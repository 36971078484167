import * as React from "react"

function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  }

export default function ProductIcon({ name, short, logo, type, href, onClick }) {

    const rawCategories = require('../../data/categories.json');

    return (<a
        class="relative block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
        onClick={() => { if (onClick != null) onClick(); }}
        href={href}
    >
        <span class="inline-block rounded-lg p-3" style={{ backgroundColor: rawCategories[type].color, color: pickTextColorBasedOnBgColorSimple(rawCategories[type].color, '#ffffff', '#000000') }} dangerouslySetInnerHTML={{ __html: logo }} >
        </span>

        <h2 class="mt-2 font-bold">{name}</h2>

        <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            {short}
        </p>

    </a>);

}