import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const QuestionsAndAnswers = [
    {
        "question": "Does Irishman support Semantic-HTML and Aria?",
        "answer": "Yes, we embed as much information into our pages as possible, allowing for assistance devices to fully understand our pages and the flurry of information stored within them. If you find any part of our website that needs additional support for Semantic-HTML, Aria, and similar systems, please contact us. Also, if you would like to suggest additional support of a system that could benefit you or a group, make sure to let us know because we would be eager to implement it."
    },
    {
        "question": "How does Irishman make coding more equitable and inclusive?",
        "answer": "We feel that the interface of programming should not bar any members on the ability-spectrum from work. Because of this, we offer many systems that allow for the processing of code into 'auditory-representations', or representations that are more sensible for assistance devices. If there are any programs that you believe Irishman could offer to create a more inclusive landscape, please let us know and we will see how we can go about implementing them."
    },
    {
        "question": "Does Irishman meet the WCAG standard?",
        "answer": "Yes! In fact, we meet the WCAG 2.2 standard on all of our webpages because we feel it is important for every developer to be able to access our technology. We understand a simple fact- helping one developer helps all developers."
    },
    {
        "question": "How does Irishman strive to lower language barriers?",
        "answer": "We have begun working on AI, language-based translation programs that will soon be publicly available. These programs will allow anyone to translate text from one language to another, and will be conveniently at one of the lowest market prices."
    }
];

const DetailsGroup = ({ question, answer }) => (
    <details class="group">
        <summary
            class="flex cursor-pointer items-center justify-between rounded-lg bg-gray-50 p-4"
        >
            <h2 class="font-medium text-gray-900">
                {question}
            </h2>

            <svg
                class="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                />
            </svg>
        </summary>

        <p class="mt-4 px-4 leading-relaxed text-gray-700">
            {answer}
        </p>
    </details>
)

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData.filter(({ }, i) => i < 6);

    return < Layout >
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        Accessibility
                    </h1>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        We pride ourselves on being an organization that is designed with people of across the ability-spectrum. Because of this, we have assembled questions and answers about our offerings.
                    </p>
                </div>
            </div>
        </section>
        <section>
            <div class="space-y-4 mx-auto max-w-screen-lg px-4 py-32 my-16">
                {
                    QuestionsAndAnswers.map(data => DetailsGroup(data))
                }
            </div>
        </section>
    </Layout >
}

export const Head = () => <Seo title="Accessibility" children={<>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": QuestionsAndAnswers.map(QA => ({
                "@type": "Question",
                "name": QA.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": QA.answer
                }
            }))
        })
    }} />
</>} />

export default IndexPage
